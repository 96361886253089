import React, { useState } from 'react';
import { RecordContextProvider, TextField, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { Button, Collapse, Link as MuiLink, styled, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';
import {
  PlaylistAddCheck as ReadyIcon,
  Inbox as AssignedIcon,
  Clear as DeclinedIcon,
  LocationOff as NoShowIcon,
  Announcement as RequestedIcon,
  RemoveCircleOutline as RemovedIcon,
  Warning as ExpiredIcon,
  Block as EligibilityIcon,
  Group as ConflictIcon,
  Schedule as ScheduleIcon,
  DirectionsRun as TravelIcon,
  Today as AssignedSameDayIcon,
  EventAvailable as AvailableIcon,
  EventBusy as UnavailableIcon,
  Voicemail as AccountIcon,
  Gavel as SuspendedIcon,
  SupervisorAccount,
  Group,
  ExpandLess,
  ExpandMore,
  AccountBalance,
  CompareArrows,
} from '@material-ui/icons';
import moment from 'moment-timezone';

import { isEmpty } from '@hisports/parsers';
import { KEEPER_POSITIONS } from '@hisports/scoresheet/src/constants';
import { isKeeper } from '@hisports/scoresheet/src/util';

import { useRichTranslate } from '../../../../../../common/useRichTranslate';
import { InviteDialog } from '../../../../../participants/InviteDialog';
import { OfficeField } from '../../../../../offices/OfficeField';
import { ParticipantField } from '../../../../../participants/ParticipantField';
import { AttributeTypeField } from '../../../../../attributeTypes/AttributeTypeField';

import { useAssignmentsContext } from '../../AssignmentsContext';
import { hasFlag, filterFlags, getMeta, isReady } from '../util';
import { useAssignmentContext } from '../AssignmentContext';
import DelegateButton from '../AssignToolbar/DelegateButton';
import UndoButton from '../AssignToolbar/UndoButton';


const Alert = styled(MuiAlert)({
  marginBottom: 8,
})

const Flag = styled('div')({
  marginBottom: 4,
})

const Note = styled('p')(({ theme }) => ({
  margin: theme.spacing(1),
  fontStyle: 'italic',
  whiteSpace: 'pre-line',
}))

const AssignerIcon = styled(SupervisorAccount)({
  position: 'relative',
  top: '5px',
  paddingRight: '4px',
  paddingTop: '8px'
})

const ConsecutiveIcon = styled(CompareArrows)({
  position: 'relative',
  top: '5px',
  paddingLeft: '4px',
})

const Assigner = ({ participantId }) => {
  const richTranslate = useRichTranslate();
  if (!participantId) return null;

  const assigner = <AssignerField participantId={participantId} />
  return <Typography display="block" variant="overline">
    <AssignerIcon fontSize="small" />
    {richTranslate('resources.games.messages.assignment.assigned_by', { name: assigner })}
  </Typography>
}

const listLevel = (levels, position, translate) => {
  const level = levels && levels[position];
  if (level == null) return;
  if (level === 0) return translate('resources.qualifications.values.level.level_zero')
  return `${translate('resources.qualifications.fields.level')} ${level}`
}

const listGrade = (grades, position, translate) => {
  const grade = grades && grades[position];
  if (!grade) return;
  return ` / ${translate('resources.qualifications.fields.grade')} ${grade}`
}

const InviteButton = ({ participantId }) => {
  const translate = useTranslate();
  const [ open, setOpen ] = useState(false);

  return <>
    <Button color="inherit" size="small" onClick={() => setOpen(true)}>{translate('ra.action.send_invite')}</Button>
    <InviteDialog open={open} participantId={participantId} onClose={() => setOpen(false)} />
  </>
}

const Notes = ({ notes }) => {
  if (!notes) return null;
  return <Note>{notes}</Note>
}

const GameDetailsList = ({ meta, filterAffiliate = false, filterRostered = false }) => {
  const translate = useTranslate();
  if (!meta) return;

  let games = [];
  if (meta.games?.length) games.push(...meta.games)
  if (meta.practices?.length) games.push(...meta.practices);
  if (meta.activities?.length) games.push(...meta.activities);

  if (filterAffiliate) {
    games = games.filter(game => !game?.isAffiliate)
  }
  if (filterRostered) {
    games = games.filter(game => game?.isAffiliate)
  }

  if (!games.length) return null;
  return <ul>
    {games.map(game => <li key={game.id}>
      {game.summary}
      {game.consecutive && <Tooltip fontSize="small" title={translate('resources.games.messages.assignment.consecutive_game')}><ConsecutiveIcon /></Tooltip>}
    </li>)}
  </ul>
}

const OfficialInfo = ({ official, flags, assignment, role }) => {
  const translate = useTranslate();
  if (!official) return null;
  const isKeeperAssignment = isKeeper(assignment);
  const hasScorekeeperType = official?.types?.find(type => KEEPER_POSITIONS.includes(type))

  const officialType = isKeeperAssignment && hasScorekeeperType ? 'Scorekeeper' : 'Official'

  const location = official.city ? official.city : <OfficeField source="officeId" addLabel={false} allowEmpty />
  const isExpired = hasFlag(flags, 'Expired')
  const participant = <Link to={`/participants/${official.participantId}/show`} component={MuiLink}>{official.participant.fullName.toUpperCase()}</Link>
  const roleMessage = role === 'assigner'
    ? <>{participant} {!isExpired ? translate('ra.function.is') : translate('ra.function.was')} {translate('ra.function.a')}</>
    : <>{translate('ra.function.youre')} {translate('ra.function.a')}</>
  return <p>{roleMessage} <strong>{listLevel(official.levels, officialType, translate)}{listGrade(official.grades, assignment.position, translate)}</strong> {translate(`resources.games.messages.assignment.${officialType.toLowerCase()}_from`)} {location}.</p>
}

const ReadyAlert = ({ official, flags, assignment, role }) => {
  const translate = useTranslate();
  if (!isReady(flags)) return null;

  return <Alert icon={<ReadyIcon />} severity="info">
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.ready.title`)}</AlertTitle>
    {translate(`resources.games.alerts.assignment.${role}.ready.officiate_game`)}
  </Alert>
}

export const AssigningOfficeField = ({ officeId }) => {
  const cachedOffice = useSelector(state => state?.admin?.resources?.offices?.data?.[officeId])

  if (cachedOffice?.name) return cachedOffice?.name

  return <RecordContextProvider value={{ officeId }}>
    <OfficeField source="officeId" link={false} variant="inherit" />
  </RecordContextProvider>
}

const AssignerField = ({ participantId }) => {
  return <RecordContextProvider value={{ participantId }}>
    <ParticipantField source="participantId" link={false} variant="inherit" />
  </RecordContextProvider>
}

const SettingsAlert = props => {
  const { assignment, filters, meta: { canAssign, fee, isDelegated } } = useAssignmentContext();
  const { game, settings, refetch } = useAssignmentsContext();
  const translate = useTranslate();

  if (!canAssign || fee != null) return null;
  return <Alert
    severity="warning"
    icon={<AccountBalance />}
    action={isDelegated && <DelegateButton
      game={game}
      assignment={assignment}
      filters={filters}
      settings={settings}
      onClose={refetch}
      color="inherit"
      size="small"
    />}
  >
    <AlertTitle>{translate('resources.games.alerts.assignment.missing_settings')}</AlertTitle>
    {translate(`resources.games.alerts.assignment.no_pay_structure${isDelegated ? '_delegated' : ''}`)}
  </Alert>
}

export const AssignerAlert = props => {
  const { assignment, filters, meta } = useAssignmentContext();
  const { game, settings, refetch } = useAssignmentsContext();
  const translate = useRichTranslate()
  const { isDelegated, isDelegatedPay, canAssign } = meta;
  const assigningOfficeId = isDelegated ? assignment.officeId : settings.officeId
  const payOfficeId = isDelegatedPay ? assignment.payOfficeId : settings.officeId;

  const assigningOffice = <AssigningOfficeField officeId={assigningOfficeId} />
  const payOffice = <AssigningOfficeField officeId={payOfficeId} />

  if (!assigningOfficeId) return null

  return <Alert icon={<Group />} severity="info" variant="outlined" action={
    canAssign && <DelegateButton
      game={game}
      assignment={assignment}
      filters={filters}
      settings={settings}
      onClose={refetch}
      color="inherit"
      size="small"
    />} {...props}>
    {translate(`resources.games.alerts.assignment.assigned_by${isDelegated && canAssign ? '_delegated' : ''}`, { office: assigningOffice, payOffice })}
  </Alert>
}

const StatusAlert = ({ official, flags, assignment, game, role }) => {
  const translate = useTranslate();

  const isAssigned = hasFlag(flags, 'Assigned');
  const isDeclined = hasFlag(flags, 'Declined');
  const isNoShow = hasFlag(flags, 'No Show');
  const isRequested = hasFlag(flags, 'Requested');
  const isRemoved = hasFlag(flags, 'Removed');
  const isConfirmed = assignment.status === 'confirmed'
  const otherPosition = assignment.participantId !== official.participantId

  if (isAssigned) {
    const assigner = getMeta(official.meta, 'Assigned', 'assigner')
    if (isConfirmed) return <Alert severity="success">
      <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.confirmed.title`)}</AlertTitle>
      {translate(`resources.games.alerts.assignment.${role}.confirmed.${otherPosition ? 'accepted_other' : 'accepted'}`)}
      <Notes notes={getMeta(official.meta, 'Assigned', 'notes')} />
      <Assigner participantId={assigner} />
    </Alert>

    const isConflict = ['Conflict', 'Cancelled', 'Postponed'].includes(game.status);
    let message = null;
    if (role === 'assignee') {
      message = isConflict ? translate('resources.games.alerts.assignment.assignee.pending.confirm_conflict') : translate('resources.games.alerts.assignment.assignee.pending.confirm')
    } else if (role === 'assigner') {
      message = otherPosition ? translate('resources.games.alerts.assignment.assigner.pending.unconfirmed_other') : translate('resources.games.alerts.assignment.assigner.pending.unconfirmed')
    }

    return <Alert icon={<AssignedIcon />} severity="info">
      <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.pending.title`)}</AlertTitle>
      {message}
      <Notes notes={getMeta(official.meta, 'Unconfirmed', 'notes')} />
      <Assigner participantId={assigner} />
    </Alert>
  }

  if (isDeclined) {
    const assigner = getMeta(official.meta, 'Declined', 'assigner')
    return <Alert icon={<DeclinedIcon />} severity="error">
      <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.declined.title`)}</AlertTitle>
      <div dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.declined.${otherPosition ? 'change_response_other' : 'change_response'}`) }} />
      <Notes notes={getMeta(official.meta, 'Declined', 'notes')} />
      <Assigner participantId={assigner} />
    </Alert>
  }

  if (isNoShow) {
    return <Alert icon={<NoShowIcon />} severity="error" action={role === "assigner" && <UndoButton game={game} assignment={assignment} official={official} />} >
      <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.no_show.title`)}</AlertTitle>
      {translate(`resources.games.alerts.assignment.${role}.no_show.removed`)}
    </Alert>
  }

  if (isRequested) {
    const position = getMeta(official.meta, 'Requested', 'position')
    const request = translate(`resources.games.values.assignment.position.${position}`, { _: position })
    const timestamp = getMeta(official.meta, 'Requested', 'timestamp');
    const ago = timestamp ? moment.duration(moment(timestamp).diff()).humanize(true) : '';
    return <Alert icon={<RequestedIcon />} severity="info">
      <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.requested.title`)}</AlertTitle>
      {translate(`resources.games.alerts.assignment.${role}.requested.requested`)} {request ? `(${request})` : ''} {ago}
      <Notes notes={getMeta(official.meta, 'Requested', 'notes')} />
    </Alert>
  }

  if (isRemoved) {
    const response = getMeta(official.meta, 'Removed', 'response') || 'Declined';
    return <Alert icon={<RemovedIcon />} severity="warning">
      <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.removed.title`)}</AlertTitle>
      {translate(`resources.games.alerts.assignment.${role}.removed.removed`, { message: response.toLowerCase() })}
      <Notes notes={getMeta(official.meta, 'Removed', 'notes')} />
    </Alert>
  }

  return null;
}

const ExpiredAlert = ({ official, flags, assignment, role }) => {
  const translate = useTranslate();

  const isExpired = hasFlag(flags, 'Expired');
  if (!isExpired) return null;

  return <Alert icon={<ExpiredIcon />} severity="error">
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.expired.title`)}</AlertTitle>
    <Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.expired.expired`) }} />
  </Alert>
}

const EligibilityAlert = ({ official, flags, assignment, role }) => {
  const translate = useTranslate();

  const isLevel = hasFlag(flags, 'Referee Level') || hasFlag(flags, 'Linesperson Level') || hasFlag(flags, 'Official Level') || hasFlag(flags, 'Scorekeeper Level') || hasFlag(flags, 'Timekeeper Level');
  const isGrade = hasFlag(flags, 'Referee Grade') || hasFlag(flags, 'Linesperson Grade');
  const isUnderage = hasFlag(flags, 'Underage');
  const reachedDailyLimit = hasFlag(flags, 'Daily Limit');
  const { position } = assignment;

  const reasons = [];
  if (isLevel && official.level != null) {
    reasons.push(<Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.eligibility.minimum_level`, { position: translate(`resources.games.values.assignment.position.${position}`) }) }} />)
  }

  if (isGrade) {
    const grade = official.grades && official.grades[position]
    if (!grade) {
      reasons.push(<Flag>{translate(`resources.games.alerts.assignment.${role}.eligibility.no_grade`)}</Flag>)
    } else {
      reasons.push(<Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.eligibility.minimum_grade`, { position: translate(`resources.games.values.assignment.position.${position}`) }) }} />)
    }
  }

  if (isUnderage) {
    const missingAge = getMeta(official.meta, 'Underage', 'missing')
    if (missingAge) {
      reasons.push(<Flag>{translate(`resources.games.alerts.assignment.${role}.eligibility.no_age`)}</Flag>)
    } else {
      reasons.push(<Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.eligibility.minimum_age`) }} />)
    }
  }

  if (reachedDailyLimit) {
    const limit = getMeta(official.meta, 'Daily Limit', 'dailyLimit');
    reasons.push(<Flag>{translate(`resources.games.alerts.assignment.${role}.eligibility.limit_reached`, { smart_count: limit })}</Flag>)
  }

  if (!reasons.length) return null;

  return <Alert icon={<EligibilityIcon />} severity={role === 'assigner' ? "warning" : "error"}>
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.eligibility.title`)}</AlertTitle>
    {reasons}
  </Alert>
}

const ConflictAlert = ({ official, flags, role }) => {
  const translate = useTranslate();

  const isConflict = hasFlag(flags, 'Team Conflict');
  const isRoster = hasFlag(flags, 'Roster');

  const reasons = [];
  if (isConflict) reasons.push(<Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.conflicts.team_conflict`) }} />)
  if (isRoster) reasons.push(<Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.conflicts.rostered`) }} />)

  if (!reasons.length) return null;

  return <Alert icon={<ConflictIcon />} severity="error">
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.conflicts.title`)}</AlertTitle>
    {reasons}
  </Alert>
}

const ScheduleAlert = ({ official, flags, role }) => {
  const translate = useTranslate();

  const isPlaying = hasFlag(flags, 'Roster Conflict');
  const isConflict = hasFlag(flags, 'Game Conflict');
  const isOverlap = hasFlag(flags, 'Game Overlap');

  let isPlayingRostered = false;
  const reasons = [];
  if (isPlaying) {
    const meta = getMeta(official.meta, 'Roster Conflict');
    const conflicts = [...(meta.games || []), ...(meta.practices || []), ...(meta.activities || [])];
    const hasRosteredConflict = conflicts.some(conflict => !conflict?.isAffiliate);
    const hasAffiliateConflict = conflicts.some(conflict => conflict?.isAffiliate);

    if (hasRosteredConflict) {
      reasons.push(<Flag>
        <div dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.schedule.roster_conflict`) }} />
        <GameDetailsList meta={meta} filterAffiliate />
      </Flag>)
    }
    if (hasAffiliateConflict) {
      reasons.push(<Flag>
        <div dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.schedule.affiliate_conflict`) }} />
        <GameDetailsList meta={meta} filterRostered />
      </Flag>)
    }

    isPlayingRostered = hasRosteredConflict;
  }
  if (isConflict) reasons.push(<Flag>
    <div dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.schedule.game_conflict`) }} />
    <GameDetailsList meta={getMeta(official.meta, 'Game Conflict')} />
  </Flag>)
  if (isOverlap) reasons.push(<Flag>
    {translate(`resources.games.alerts.assignment.${role}.schedule.game_overlap`)}
    <GameDetailsList meta={getMeta(official.meta, 'Game Overlap')} />
  </Flag>)

  if (!reasons.length) return null;

  const isError = isPlayingRostered || isConflict;
  return <Alert icon={<ScheduleIcon />} severity={isError ? "error" : "warning"}>
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.schedule.title`)}</AlertTitle>
    {reasons}
  </Alert>
}

const TravelAlert = ({ official, flags, role }) => {
  const translate = useTranslate();

  const isTravel = hasFlag(flags, 'Travel');
  const isArena = hasFlag(flags, 'At Arena');

  const reasons = [];
  if (isTravel) reasons.push(<Flag>
    <div dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.travel.another_venue`) }} />
    <GameDetailsList meta={getMeta(official.meta, 'Travel')} />
  </Flag>)

  if (isArena) reasons.push(<Flag>
    {translate(`resources.games.alerts.assignment.${role}.travel.this_venue`)}
    <GameDetailsList meta={getMeta(official.meta, 'At Arena')} />
  </Flag>)

  if (!reasons.length) return null;

  const isWarning = isTravel;
  return <Alert icon={<TravelIcon />} severity={isWarning ? "warning" : "info"}>
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.travel.title`)}</AlertTitle>
    {reasons}
  </Alert>
}

const AssignedSameDayAlert = ({ official, flags, role }) => {
  const translate = useTranslate();

  const isAssignedSameDay = hasFlag(flags, 'Assigned Today');
  const isTravel = hasFlag(flags, 'Travel');
  const isArena = hasFlag(flags, 'At Arena');
  const isConflict = hasFlag(flags, 'Game Conflict');

  if (!isAssignedSameDay || isTravel || isArena || isConflict) return null;
  return <Alert icon={<AssignedSameDayIcon />} severity="info">
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.travel.title_assigned_today`)}</AlertTitle>
    <Flag>
      <div dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.travel.assigned_today`) }} />
      <GameDetailsList meta={getMeta(official.meta, 'Assigned Today')} />
    </Flag>
  </Alert>
}

const AvailabilityAlert = ({ official, flags, ignoreArena, role }) => {
  const translate = useTranslate()

  const isAssigned = hasFlag(flags, 'Assigned');
  const isUnavailable = hasFlag(flags, 'Unavailable')
  const isUnknown = hasFlag(flags, 'Availability')
  const isOffice = hasFlag(flags, 'Office')
  const isArena = hasFlag(flags, 'Arena')

  const reasons = []
  if (isUnavailable) reasons.push(<Flag>
    <div dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.unavailable.unavailable`) }} />
    <Notes notes={getMeta(official.meta, 'Unavailable', 'notes')} />
  </Flag>)
  if (isUnknown) reasons.push(<Flag>{translate(`resources.games.alerts.assignment.${role}.unavailable.no_availability`)}</Flag>)
  if (isOffice) reasons.push(<Flag>{translate(`resources.games.alerts.assignment.${role}.unavailable.no_availability_office`)}</Flag>)
  if (isArena && !ignoreArena) reasons.push(<Flag>{translate(`resources.games.alerts.assignment.${role}.unavailable.no_availability_arena`)}</Flag>)

  if (!reasons.length) {
    if (isAssigned) return null;
    return <Alert icon={<AvailableIcon />} severity="info">
      <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.available.title`)}</AlertTitle>
      {translate(`resources.games.alerts.assignment.${role}.available.assigned`)}
      <Notes notes={getMeta(official.meta, 'Availability', 'notes')} />
    </Alert>;
  }

  const isError = isUnavailable || isOffice;
  return <Alert icon={<UnavailableIcon />} severity={isError ? 'error' : 'warning'}>
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.unavailable.title`)}</AlertTitle>
    {reasons}
    <Notes notes={getMeta(official.meta, 'Availability', 'notes')} />
  </Alert>
}

const AccountAlert = ({ official, flags, role }) => {
  const translate = useTranslate();

  const unverified = hasFlag(flags, 'Not Verified');
  const invited = hasFlag(flags, 'Invited');
  const unregistered = hasFlag(flags, 'Not Registered');

  const reasons = [];
  if (unverified) reasons.push(<Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.account.unverified`) }} />)
  if (invited) reasons.push(<Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.account.invited`) }} />)
  if (unregistered) reasons.push(<Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.account.unregistered`) }} />)

  if (!reasons.length) return null;

  const isError = invited || unregistered;
  const showInvite = role === "assigner" && isError;
  return <Alert
    icon={<AccountIcon />}
    severity={isError ? "error" : "warning"}
  >
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.account.title`)}</AlertTitle>
    {reasons}
    {showInvite && <InviteButton participantId={official.participantId} />}
  </Alert>
}

const SuspendedAlert = ({ official, flags, assignment, role }) => {
  const translate = useTranslate();

  const isSuspended = hasFlag(flags, 'Suspended');
  if (!isSuspended) return null;

  const suspension = getMeta(official.meta, 'Suspended').suspension;
  const { effectiveRequiredGames, expiry, effectiveDurationType, positionGroup, date } = suspension;

  const durationMessage = effectiveDurationType === 'Indefinite'
    ? translate('resources.games.alerts.assignment.assigner.suspended.duration_indefinite')
    : `${translate('resources.games.alerts.assignment.assigner.suspended.duration', effectiveRequiredGames)}${expiry ? translate('resources.games.alerts.assignment.assigner.suspended.duration_expiry', { date: moment.utc(expiry).format('LL') }) : ''}`

  return <Alert icon={<SuspendedIcon />} severity="error">
    <AlertTitle>{translate(`resources.games.alerts.assignment.${role}.suspended.title`)}</AlertTitle>
    <Flag dangerouslySetInnerHTML={{ __html: translate(`resources.games.alerts.assignment.${role}.suspended.suspended`, {
      duration: durationMessage,
      positionGroup: translate(`resources.participants.labels.status.types.${positionGroup}`, { _: positionGroup }).toLowerCase(),
      date: moment.utc(date).format('LL') })
    }} />
  </Alert>
}

const AttributesTable = ({ official, role }) => {
  const translate = useTranslate();
  const showAttributeValues = role === 'assigner'
  const [ showAttributes, setVisible ] = useState(false);

  const { attributeValues: values } = official;
  if (isEmpty(values) || !showAttributeValues) return null

  return <p>
    <Collapse in={showAttributes}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{translate(`resources.attributeValues.fields.attributeId`)}</TableCell>
            <TableCell>{translate(`resources.attributeValues.fields.value`)}</TableCell>
            <TableCell>{translate(`resources.attributeValues.fields.officeId`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map(value =>
            <TableRow>
              <RecordContextProvider value={value}>
                <TableCell><AttributeTypeField source="attributeId" /></TableCell>
                <TableCell><TextField source="value" /></TableCell>
                <TableCell><OfficeField source="officeId" link={false} /></TableCell>
              </RecordContextProvider>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Collapse>
    <Button
      variant="text"
      size="small"
      startIcon={showAttributes ? <ExpandLess /> : <ExpandMore />}
      onClick={() => setVisible(visible => !visible)}
    >
      {translate(showAttributes ? 'resources.games.labels.assignment.hide_attributes' : 'resources.games.labels.assignment.show_attributes')}
    </Button>
  </p>
}

export default ({ role }) => {
  const { assignment, official, filters } = useAssignmentContext();
  const { game } = useAssignmentsContext();

  if (!assignment || !official) return null;

  const flags = filterFlags(official.flags, assignment.position, filters)

  return <RecordContextProvider value={official}>
    <OfficialInfo official={official} flags={flags} assignment={assignment} role={role} />
    <AttributesTable official={official} flags={flags} assignment={assignment} role={role} />

    <ReadyAlert official={official} flags={flags} assignment={assignment} role={role} />

    <StatusAlert official={official} flags={flags} assignment={assignment} game={game} role={role} />
    <ExpiredAlert official={official} flags={flags} assignment={assignment} role={role} />
    <SuspendedAlert official={official} flags={flags} role={role} />
    <EligibilityAlert official={official} flags={flags} assignment={assignment} role={role} />
    <ConflictAlert official={official} flags={flags} role={role} />
    <ScheduleAlert official={official} flags={flags} role={role} />
    <TravelAlert official={official} flags={flags} role={role} />
    <AssignedSameDayAlert official={official} flags={flags} role={role} />
    <AvailabilityAlert official={official} flags={flags} ignoreArena={filters.ignoreArena} role={role} />
    <AccountAlert official={official} flags={flags} role={role} />
    <SettingsAlert official={official} flags={flags} role={role} />
    <AssignerAlert />
  </RecordContextProvider>
}
