import React from 'react';
import { ReferenceField } from 'react-admin';
import { ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import { FlipCameraAndroid } from '@material-ui/icons';
import moment from 'moment-timezone';

import FunctionField from '../../../common/fields/FunctionField';
import { EnumField } from '../../../common/inputs/EnumInputs';
import { useRichTranslate } from '../../../common/useRichTranslate';
import { TextLocaleField } from '../../../common/fields/TextLocaleField';

import { ParticipantField } from '../../participants/ParticipantField';
import { GameField } from '../../games/GameField';
import { TeamField } from '../../teams/TeamField';
import { ScheduleField } from '../../schedules/ScheduleField';

import { PenaltyField } from '../gameIncidents/IncidentReportGrid';
import { SuspensionDatagrid, SuspensionDialogDatagrid } from './SuspensionModal';
import { GamePurgeList } from './GamePurgeList';

const useStyles = makeStyles(theme => ({
  expandedPanel: {
    "& > td": {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  },
  icon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(0.5),
    verticalAlign: 'middle'
  }
}))

const AutomaticSanctionSuspensionIcon = () => {
  const classes = useStyles()
  const translate = useRichTranslate()

  return <Tooltip
    title={translate('resources.suspensions.alerts.automatic.title')}
  >
    <FlipCameraAndroid className={classes.icon} />
  </Tooltip>
}

const SanctionField = ({ variant = 'body2', ...props }) => {
  return <ReferenceField basePath="/sanctions" resource="sanctions" reference="sanctions" {...props} link={false}>
    <FunctionField source="name" render={sanction => <TextLocaleField source="name" variant={variant} />} />
  </ReferenceField>
}

const renderSuspensionDetails = (suspension, source, resource, translate) => {
  const positionGroup = translate(`resources.suspensions.values.positionGroup.${suspension.positionGroup}`, { _: suspension.positionGroup })
  const effectiveRequiredGames = translate(`resources.suspensions.messages.required_games`, suspension.effectiveRequiredGames)
  const expiryDate = suspension.expiry && moment.utc(suspension.expiry, 'YYYY-MM-DD').format("YYYY-MM-DD")
  const sanctionName = suspension.sanctionId ? <SanctionField source="sanctionId" /> : translate(`resources.suspensions.values.code.${suspension.code || 'supplemental'}`, { _: suspension.code })

  const primary = <div>
    {sanctionName}
    {(!!suspension?.code || !!suspension?.sanctionId) && <AutomaticSanctionSuspensionIcon />}
  </div>

  let secondary = `${effectiveRequiredGames}${expiryDate ? ` / ${expiryDate}` : ''} (${positionGroup})`
  if (suspension.effectiveDurationType === 'Indefinite') {
    secondary = translate('resources.suspensions.values.durationType.Indefinite')
  }

  return <ListItemText
    primaryTypographyProps={{ variant: "body2" }}
    primary={primary}
    secondary={secondary}
    style={{ textWrap: 'pretty' }}
  />
}

export const SuspensionDetailsField = props =>
  <FunctionField render={renderSuspensionDetails} {...props} />

export const SuspensionGrid = ({ multipleGames = false, hideTeam = false, hideParticipant = false, showDialog = false, canExpand = true, ...props }) => {
  const classes = useStyles()

  const Datagrid = showDialog ? SuspensionDialogDatagrid : SuspensionDatagrid;

  return <Datagrid
    size="medium"
    expand={canExpand && <GamePurgeList />}
    classes={classes}
    {...props}
  >
    {!hideParticipant && <ParticipantField source="participantId" includeId="full" />}
    {!hideTeam && <TeamField source="teamId" includeId="full" />}
    {multipleGames && <ReferenceField source="gameId" reference="games" resource="games" basePath="/games" label="resources.games.fields.scheduleId" link={false}>
      <ScheduleField source="scheduleId" includeCategory="full" includeType="full" link={false} />
    </ReferenceField>}
    {multipleGames && <GameField source="gameId" link="show" includeDate="inline" includeSeason="full" />}
    {!multipleGames && <PenaltyField source="penaltyId" />}
    <SuspensionDetailsField label="resources.suspensions.labels.details" />
    <EnumField source="status" />
  </Datagrid>
}
